@font-face {
  font-family: Karam;
  src: url(./Karam/CAREEM.otf);
}

* {
  font-family: Karam;
}

#BtnSidbarContinerOut {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#sideDivider {
  width: 2px;
  height: 80px;
  background-color: #ffffff;
}

.active1 #BtnSidbarContiner {
  border-bottom: 5px solid #fbc85c;
  padding-top: 5px;
  color: #fbc85c;
}

.active2 #BtnSidbarContiner {
  border-bottom: 5px solid #4cc035;
  padding-top: 5px;
  color: #4cc035;
}

.active3 #BtnSidbarContiner {
  border-bottom: 5px solid #7063e6;
  padding-top: 5px;
  color: #7063e6;
}

.active4 #BtnSidbarContiner {
  border-bottom: 5px solid #3d70b5;
  padding-top: 5px;
  color: #3d70b5;
}

.active5 #BtnSidbarContiner {
  border-bottom: 5px solid #38d9bb;
  padding-top: 5px;
  color: #38d9bb;
}

.active6 #BtnSidbarContiner {
  border-bottom: 5px solid #eb596d;
  padding-top: 5px;
  color: #eb596d;
}

.active7 #BtnSidbarContiner {
  border-bottom: 5px solid #e9851d;
  padding-top: 5px;
  color: #e9851d;
}

.active8 #BtnSidbarContiner {
  border-bottom: 5px solid #1dcde9;
  padding-top: 5px;
  color: #1dcde9;
}

.active9 #BtnSidbarContiner {
  border-bottom: 5px solid #72ffcb;
  padding-top: 5px;
  color: #72ffcb;
}

#BtnSidbarContinerOut:hover {
  border-bottom: 5px solid #e74e48;
  padding-top: 5px;
  color: #e74e48;
  cursor: pointer;
}

#InputTExtDash260 {
  width: 260px;
  height: 40px;
  border-radius: 5px;
  border: 1px #000 solid;
  outline: none;
  text-align: right;
  padding-right: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#ColHome {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

#CardHome1,
#CardHome2,
#CardHome3,
#CardHome4 {
  height: 100px;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  cursor: pointer;
  text-align: right;

}

#CardHome1 {
  background-color: #20c997;
}

#CardHome2 {
  background-color: #ffc107;
}

#CardHome3 {
  background-color: #17a2b8;
}

#CardHome4 {
  background-color: #dc3545;
}

#colItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.cardItemContiner {
  position: relative;
  width: 250px;
  height: 320PX;
}

.ImageItem {
  height: 140px;
  width: 250px;
}

#BtnAddUser {
  width: 100px;
  height: 40px;
  background-color: #4cc035;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#BtnAddUser:hover,
#AddClientDeptBTN:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#BtnAddUser:active,
#AddClientDeptBTN:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#titleAddUser {
  width: 100;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4cc035;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#inputAdd2Continer {
  direction: rtl;
  margin: 20px;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#inputAddContiner {
  padding: 0px 20px;
  direction: rtl;
}

#ContinerInPut {
  display: flex;
  justify-content: flex-start;
  margin: 20px 30px;

}

#ContinerInPut label {
  height: 40px;
  display: flex;
  align-items: center;
  width: 33%;

}

#InputTExtDash111 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#InputTExtDash111auto {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  /* padding-left: 20px; */
  background-color: #fafafa;
}

#InputTExtDash111Image {
  width: 260px;
  height: 33px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fafafa;
}

#spinnerEdit {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#date1 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-left: 20px;
  background-color: #fafafa;
}

#textAreaAddUser {
  width: 260px;
  min-height: 60px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#btnAddContiner {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
}

#descCardItem {
  width: 100%;
  height: 50PX;
  padding: 5px;
  text-align: center;
  overflow: hidden;
}

#btnHeaderContiner {
  height: 100px;
  width: 100%;
  display: flex;
  padding: 0px 30px;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
}

#countITemContiner {
  margin-top: 10px;
  height: 25px;
  width: 50px;
  position: absolute;
  background-color: #3d70b5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 20px 20px 0px;
}

#priceITemContiner {
  margin-top: 10px;
  height: 25px;
  width: 100px;
  position: absolute;
  right: 0px;
  background-color: #3d70b5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0px 0px 20px;
}

#ADDANDSubContiner {
  display: flex;
  justify-content: center;
  align-items: center;
}

#ADDITEM {
  margin: 10px;
  cursor: pointer;
}

#menuFolderCateContiner {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

#messageExpireContiner {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

#MenuBtnCateCont {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(189, 181, 181, 0.432);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#msgDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}

#menuEditAndDeleteContinerCate {
  margin-bottom: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#MenuBtnCateCont {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(189, 181, 181, 0.432);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#selectAndAddContiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#selectBtn {
  height: 25px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #3d70b5;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#CancelBtn {
  height: 25px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #cf6060;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#dayExpireContiner {
  align-items: center;
  display: flex;
}

#statusColor {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

#NavHistoryContiner {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

#ContinerReportDate {
  margin: 30px;
  min-height: 60px;
  background-color: rgba(128, 210, 172, 0.2);
  border-radius: 10px;
}

#RowReportDate {
  margin: 0px;
}

#ColReportDate {
  display: flex;
  align-items: center;
  justify-content: center;
}

#date1Repo,
#date2Repo {
  width: 220px;
  height: 40px;
  /* padding-right: 10px; */
  outline: none;
  margin: 5px 0px;
  border: #38d9bb 1px solid;
  border-radius: 5px;
  text-align: center;
}

#AddClientDeptBTN {
  width: 100px;
  height: 40px;
  background-color: #38d9bb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#ColReport {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

#CardReportContiner {
  height: 70px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #5e8fac;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
}

#CardReportContinerSafe {
  height: 70px;
  width: 96%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #5e8fac;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
}

#expenseslabel {
  height: 70;
  width: 100%;
  margin: 20px 0px;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #17a2b8;
}

#linelabelexpen {
  height: 3px;
  width: 40%;
  background-color: #17a2b8;
}

#ifmcontentstoprint,
#ifmcontentstoprintL {
  display: none;
  font-size: 50px;
  font-weight: 600px;
  height: 540.47244094px;
  width: 793.7007874px;
}

.homeNav {
  width: 100%;
  height: 80px;
  background: #282c34;
  margin-bottom: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 15px;
}

#hederPrint {
  /* height: 20%; */
  width: 96%;
  margin: 25px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  direction: rtl;
}

#logoPrint {
  margin-top: 20px;
  /* height:200px; */
  width: 300px;
}

#linePrint {
  margin-top: 30px;
  margin: 2%;
  width: 96%;
  height: 2px;
  -webkit-print-color-adjust: exact;
  background-color: #000 !important;
}

#hederTablePrint {
  margin: 30px 0px;
  direction: rtl;
  text-align: center;
  min-height: 500px;
}

#hederTableTextPrint {
  width: 100%;
  font-size: 20px;

}

#students {
  margin-left: 30px;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 1px solid #000;
  width: 760px;
  direction: rtl;
  font-size: 20px;
  color: #000;
}

#students td,
#students th {
  border: 1px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {

  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726 !important;
  color: black;
}

#ConTENTList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

#ConTENTList1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  border: #000 1px solid;
  border-radius: 0 5px 5px 0;
  width: 100%;
  -webkit-print-color-adjust: exact;
  background-color: #eee !important;
}

#ConTENTList2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border: #000 1px solid;
  border-radius: 5px 0 0 5px;
  width: 100%;
}

#CollL {

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

#COOON {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 2px solid #5c9bdf;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
}

#ConTENTListN {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

#ConTENTList3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border: #000 1px solid;
  width: 100%;
  -webkit-print-color-adjust: exact;
  background-color: #eee !important;
}

#ConTENTList4 {
  display: flex;
 
  padding-top: 10px;
  height: 130px;
  border: #000 1px solid;
  width: 100%;


}



#ConTENTList5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  height: 40px;
  border: #000 1px solid;
  width: 100%;


}

#todo {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: #363839;
  border: 1px solid #bdc1c6;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  -webkit-transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}



#todo::before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 7px;
  width: 8px;
  height: 14px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

#todo:checked {
  color: #fff;
  border-color: #06842c;
  background: #06842c;
}

#todo:checked::before {
  opacity: 1;
}

#todoLabel::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

#todoLabel {
  position: relative;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  padding: 0 0.25em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#preNotes2 {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 12px;
  
}

pre {
  overflow-x: auto;

 
}

#todoLabel::before {
  position: absolute;
  content: attr(data-content);
  color: #9c9e9f;
  -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  -webkit-text-decoration-color: #363839;
  text-decoration-color: #363839;
  -webkit-transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.activationtab {
  text-align: center;
  width: 200px;
  background-color: #d2c049;
  color: #fff;
}

.deptSubTab {
  text-align: center;
  width: 200px;
  background-color: #0c4c91;
  color: #fff;
}

.deptItemTab {
  text-align: center;
  width: 200px;
  background-color: #49b347;
  color: #fff;

}
#JJJ{
  display: flex;
}
.deptManualTab {
  text-align: center;
  width: 200px;
  background-color: #db5a60;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}